@use "../../base/shortcut-functions" as f;
@use "../modules" as modules;

.aboutus {
  main {
    .mv {
      hgroup {
        text-align: center;

        h1 {
          font-size: 7.5rem;
          font-weight: 500;
        }

        p {
          font-size: 1.5rem;
        }
      }
    }

    .intro {
      margin-inline: auto;
      max-width: 108rem;
      padding-block: 11rem 10rem;

      > hgroup {
        text-align: center;

        h2 {
          font-size: 3.6rem;
          font-weight: 300;
        }

        p {
          margin-top: 3rem;
          font-size: 1.8rem;
          line-height: 2;
        }
      }

      ul {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        gap: 7rem 4.4rem;
        margin-top: 11rem;

        li {
          display: flex;
          flex-direction: column;
          width: 33rem;

          .logo-wrapper {
            position: relative;
            width: 100%;
            aspect-ratio: 165 / 73;
            background-color: #fff;
            border-radius: 1rem;
          }

          figure {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 24.8rem;
            aspect-ratio: 248 / 43;
          }

          > hgroup {
            margin-top: 3.4rem;

            h3 {
              font-size: 1.8rem;
            }

            p {
              margin-top: 1rem;
              font-size: 1.4rem;
              line-height: 1.7;
            }
          }

          dl {
            margin-top: 1.6rem;

            dt {
              color: #237aa8;
              font-size: 1.6rem;
              font-weight: 400;
            }

            dd {
              margin-top: 0.8rem;
              padding-top: 0.6rem;
              min-height: 5rem;
              border-top: 1px solid var(--text-color);
              font-size: 1.4rem;
            }
          }

          address {
            margin-top: 1rem;

            p {
              font-size: 1.3rem;

              &.post-code {
                margin-bottom: 0.4rem;
                font-weight: 500;
              }

              &.tel {
                font-weight: 500;

                small {
                  font-size: 1rem;
                }
              }

              &.remarks {
                font-size: 1rem;
              }
            }
          }

          .external-link {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;
            margin: 0.6rem 0 0 auto;
            width: fit-content;

            p {
              font-size: 1.6rem;
              font-weight: 500;
            }

            a {
              display: inline-block;
              overflow: hidden;
              width: 6.5rem;
              aspect-ratio: 1 / 1;

              img {
                position: relative;
                top: 0;
                width: 200%;
              }

              &:hover {
                img {
                  left: -100%;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include f.mq(sp) {
  .aboutus {
    main {
      .mv {
        max-width: 70rem;

        hgroup {
          text-align: center;

          h1 {
            font-size: 7.5rem;
            font-weight: 500;
          }

          p {
            font-size: 2.4rem;
          }
        }
      }

      .intro {
        max-width: 70rem;

        > hgroup {
          text-align: center;

          h2 {
            font-size: 4.4rem;
          }

          p {
            font-size: 2.8rem;
          }
        }

        ul {
          gap: 8rem;

          li {
            width: 60rem;

            figure {
              width: 46rem;
            }

            > hgroup {
              h3 {
                font-size: 3.2rem;
              }

              p {
                font-size: 2.8rem;
              }
            }

            dl {
              dt {
                font-size: 3.2rem;
              }

              dd {
                font-size: 2.8rem;
              }
            }

            address {
              p {
                font-size: 2.8rem;

                &.tel {
                  small {
                    font-size: 2.4rem;
                  }
                }

                &.remarks {
                  font-size: 2.4rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
