@use "../../base/shortcut-functions" as f;
@use "../modules" as modules;

.company {
  main {
    dl {
      margin: 8rem auto 11rem;
      max-width: 78rem;

      > div {
        display: flex;
        align-items: flex-start;
        gap: 9rem;
        padding-block: 2rem;
        border-top: 1px solid #c4c4c4;

        dt {
          width: 10rem;
          font-size: 1.6rem;
          font-weight: 400;
          text-align-last: justify;
        }

        dd {
          font-size: 1.4rem;

          address {
            p {
              line-height: 1.7;

              &:last-of-type {
                margin-top: 2rem;
              }
            }

            .link {
              display: flex;
              gap: 0.4rem;
              margin-top: 0.8rem;

              &::before {
                content: "";
                display: block;
                width: 1.2rem;
                aspect-ratio: 12 / 17;
                background: url(../images/common/svg/access.svg) no-repeat center / contain;
              }

              a {
                color: #237aa8;
                text-decoration: underline;
              }
            }
          }
        }

        &:not(:last-of-type) {
          dd {
            ul {
              li + li {
                margin-top: 0.8rem;
              }
            }
          }
        }

        &:last-of-type {
          border-bottom: 1px solid #c4c4c4;

          dd {
            ul {
              li {
                a {
                  display: flex;
                  align-items: baseline;
                  gap: 0.4rem;
                  color: #237aa8;
                  text-decoration: underline;

                  &::after {
                    content: "";
                    display: block;
                    width: 1rem;
                    aspect-ratio: 1 / 1;
                    background: url(../images/common/svg/link_icon.svg) no-repeat center / contain;
                  }
                }

                & + li {
                  margin-top: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include f.mq(sp) {
  .company {
    main {
      dl {
        max-width: 68rem;

        > div {
          flex-direction: column;
          gap: 2rem;

          dt {
            width: auto;
            font-size: 3.4rem;
            text-align-last: initial;
          }

          dd {
            font-size: 2.8rem;

            address {
              .link {
                &::before {
                  width: 2rem;
                }
              }
            }
          }

          &:last-of-type {
            border-bottom: 1px solid #c4c4c4;

            dd {
              ul {
                li {
                  a {
                    gap: 1.6rem;

                    &::after {
                      width: 2rem;
                    }
                  }

                  & + li {
                    margin-top: 2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
