@use "../../site/variables" as v;
@use "sass:map";
@use "sass:math";
@use "sass:meta";

// general media query mixin
@function get-mq-min-width($key) {
  $width: 0;

  @each $_key, $_width in v.$break-points {
    @if $key == $_key {
      @return $width;
    }
    $width: $_width + 1;
  }

  @return 0;
}

@mixin mq-min($min-width, $media: screen) {
  $min-width: if(meta.type-of($min-width) == string, #{get-mq-min-width($min-width)}px, $min-width);
  @media #{$media} and (min-width: #{$min-width}) {
    @content;
  }
}

@mixin mq-max($max-width, $media: screen) {
  $max-width: if(meta.type-of($max-width) == string, #{map.get(v.$break-points, $max-width)}px, $max-width);
  @media #{$media} and (max-width: #{$max-width}) {
    @content;
  }
}

@mixin mq-minmax($min-width, $max-width, $media: screen) {
  $min-width: if(meta.type-of($min-width) == string, #{get-mq-min-width($min-width)}px, $min-width);
  $max-width: if(meta.type-of($max-width) == string, #{map.get(v.$break-points, $max-width)}px, $max-width);
  @media #{$media} and (min-width: #{$min-width}) and (max-width: #{$max-width}) {
    @content;
  }
}

@mixin mq($key, $media: screen) {
  @include mq-minmax($key, $key, $media) {
    @content;
  }
}

// width calculator
@function calc-vw-scalar($key, $v) {
  $base-width: map.get(v.$base-widths, $key);

  @if $v == auto {
    @return auto;
  }
  @return math.div(100vw, $base-width) * $v;
}

@function calc-vw($key, $v) {
  @if type-of($v) == "list" {
    $values: $v;
    $ret: null;
    @each $v in $values {
      $ret: $ret calc-vw-scalar($key, $v);
    }
    @return $ret;
  }

  @return calc-vw-scalar($key, $v);
}
