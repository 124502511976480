@use "../../base/shortcut-functions" as f;
@use "../modules" as modules;

.philosophy {
  main {
    .intro {
      margin: 9rem auto 0;
      max-width: 78rem;

      h2 {
        text-align: center;
        font-size: 3.6rem;
        font-weight: 300;

        &::after {
          content: "";
          display: block;
          margin: 3rem auto 0;
          width: 5rem;
          height: 0.3rem;
          background-color: #c4c4c4;
        }
      }

      dl {
        margin-top: 4.6rem;

        > div {
          dt {
            color: #bf0000;
            text-align: center;
            font-size: 2.6rem;
            font-weight: 300;
          }

          dd {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: 1px solid #c4c4c4;
            font-size: 1.6rem;
            line-height: 2;
          }

          & + div {
            margin-top: 7.8rem;
          }
        }
      }
    }

    .creed {
      margin: 9rem auto 0;
      max-width: 78rem;

      h2 {
        text-align: center;
        font-size: 3.6rem;
        font-weight: 300;

        &::after {
          content: "";
          display: block;
          margin: 3rem auto 0;
          width: 5rem;
          height: 0.3rem;
          background-color: #c4c4c4;
        }
      }

      dl {
        margin-top: 4.6rem;

        > div {
          dt {
            color: #bf0000;
            text-align: center;
            font-size: 2.6rem;
            font-weight: 300;
          }

          dd {
            margin-top: 2rem;
            padding-top: 2rem;
            font-size: 1.6rem;
            line-height: 2;
          }

          & + div {
            margin-top: 7.8rem;
          }
        }
      }
    }

    .logo-meaning {
      position: relative;
      margin-block: 9rem;

      &::before {
        content: "";
        position: absolute;
        width: 90vw;
        height: 100%;
        z-index: -1;
        background-color: #e8e8e8;
      }

      .logo-meaning-inner {
        margin-inline: auto;
        max-width: 106rem;
        padding-block: 5.3rem 6.3rem;

        h2 {
          text-align: center;
          font-size: 3.6rem;
          font-weight: 300;

          strong {
            color: #bf0000;
            font-size: 6rem;
          }
        }

        > div {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4rem;
          margin-top: 5.6rem;

          figure {
            position: relative;
            width: 29.6rem;
            aspect-ratio: 1 / 1;
            border-radius: 1rem;
            background-color: #fff;

            img {
              position: absolute;
              width: 18.4rem;
              aspect-ratio: 23 / 17;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          dl {
            dt {
              font-size: 2rem;
              font-weight: 300;
            }

            dd {
              margin-top: 2.2rem;
              font-size: 1.6rem;
              line-height: 2;

              strong {
                color: #bf0000;
                font-size: 2.4rem;
                font-weight: 300;
                vertical-align: text-top;
                line-height: 1;
              }
            }
          }
        }
      }
    }
  }
}

@include f.mq(sp) {
  .philosophy {
    main {
      .intro {
        max-width: 68rem;

        h2 {
          font-size: 4.4rem;
        }

        dl {
          > div {
            dt {
              font-size: 3.8rem;
            }

            dd {
              font-size: 2.8rem;
            }
          }
        }
      }

      .creed {
        max-width: 68rem;

        h2 {
          font-size: 4.4rem;
        }

        dl {
          > div {
            dt {
              font-size: 3.8rem;
            }

            dd {
              font-size: 2.8rem;
            }
          }
        }
      }

      .logo-meaning {
        &::before {
          width: 100vw;
        }

        .logo-meaning-inner {
          max-width: 62rem;

          h2 {
            font-size: 4.4rem;

            strong {
              color: #bf0000;
              font-size: 6rem;
            }
          }

          > div {
            flex-direction: column;

            figure {
              width: 32rem;
            }

            dl {
              dt {
                font-size: 3rem;
              }

              dd {
                font-size: 1.9rem;
                white-space: nowrap;

                strong {
                  font-size: 3rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
