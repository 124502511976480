@use "../../base/shortcut-functions" as f;
@use "../modules" as modules;

.history {
  .routes {
    display: flex;
    gap: 7.2rem;
    margin: 8.4rem auto 9.8rem;
    max-width: 108rem;

    .left {
      > dl {
        > div {
          display: flex;
          gap: 5.8rem;
          padding-block: 2rem;
          width: 70.8rem;
          border-top: 1px solid #c4c4c4;

          &:last-of-type {
            border-bottom: 1px solid #c4c4c4;
          }

          dt {
            font-weight: normal;

            > time {
              font-size: 1.8rem;

              span {
                margin-left: 0.4rem;
                font-size: 1.3rem;
              }
            }

            small {
              display: block;
              font-size: 1.1rem;
            }
          }

          dd {
            > dl {
              > div {
                display: flex;
                align-items: baseline;
                gap: 5rem;

                & + div {
                  margin-top: 1.4rem;
                }

                dt {
                  width: 5.5rem;
                  text-align: end;
                  font-weight: normal;

                  > time {
                    font-size: 1.3rem;
                  }
                }

                dd {
                  font-size: 1.4rem;

                  p {
                    & + p {
                      margin-top: 1rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .right {
      ul {
        li {
          & + li {
            margin-top: 4rem;
          }

          figure {
            > div {
              width: 30rem;
              aspect-ratio: 3 / 2;
            }

            figcaption {
              margin-top: 1rem;
              text-align: center;
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
}

@include f.mq(sp) {
  .history {
    .routes {
      justify-content: space-between;
      gap: 0;
      max-width: 68rem;

      .left {
        > dl {
          > div {
            flex-direction: column;
            gap: 1rem;
            width: 42rem;

            dt {
              > time {
                font-size: 3.2rem;

                span {
                  font-size: 2.4rem;
                }
              }

              small {
                font-size: 2.6rem;
              }
            }

            dd {
              > dl {
                > div {
                  flex-direction: column;
                  gap: 0;

                  & + div {
                    margin-top: 0;
                  }

                  dt {
                    width: fit-content;

                    > time {
                      font-size: 2.4rem;
                    }
                  }

                  dd {
                    font-size: 2.6rem;
                  }
                }
              }
            }
          }
        }
      }

      .right {
        ul {
          li {
            figure {
              > div {
                width: 25rem;
              }

              figcaption {
                font-size: 2.2rem;
              }
            }
          }
        }
      }
    }
  }
}
