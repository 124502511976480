@use "../base/shortcut-functions" as f;
@use "./modules" as modules;

:root {
  --font-noto-sans: "Noto Sans JP", sans-serif;
  --font-noto-serif: "Noto Serif JP", sans-serif;
  --font-montserrat: Montserrat, sans-serif;
  --base-color: #f0f0f0;
  --text-color: #4c565d;
}

html {
  @include f.rem-base-font-size;
  font-family: var(--font-noto-sans);
}

body {
  -webkit-text-size-adjust: 100%;
  // font-feature-settings: "palt";
}

.sp {
  display: none;
  @media (max-width: 750px) {
    display: block;
  }
}

.pc {
  display: block;
  @media (max-width: 750px) {
    display: none;
  }
}

img {
  width: 100%;
  height: auto;
}

a {
  @include modules.hover {
    opacity: 0.7;
  }
}

h1 {
  font-family: var(--font-montserrat);
}

.l-header .header__pc__subMenu__list__item__link {
  color: var(--text-color);

  &.lang {
    color: #fff;
    background-color: var(--text-color);
  }
}

.l-footer {
  padding: 0;
}

main {
  color: var(--text-color);
  // background-color: var(--base-color);

  .mv {
    margin-inline: auto;
    max-width: 108rem;
    padding-block: 20rem 11rem;

    hgroup {
      text-align: center;

      h1 {
        font-size: 7.5rem;
        font-weight: 500;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }

  .lower-content {
    margin-inline: auto;
    max-width: 108rem;
  }

  .lower-page-link {
    background-color: #e8e8e8;

    ul {
      display: flex;
      justify-content: center;
      gap: 2.9rem;
      margin-inline: auto;
      max-width: 108rem;
      padding-block: 10rem;

      li {
        position: relative;
        width: 34rem;
        aspect-ratio: 34 / 33;
        transition: opacity 0.3s ease;
        white-space: nowrap;

        a {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;

          hgroup {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #fff;
            text-align: center;

            h3 {
              font-size: 3.2rem;
              font-weight: 500;
              letter-spacing: 0.05em;
            }

            p {
              margin-top: 1rem;
              font-size: 1.8rem;
            }
          }

          figure {
            position: absolute;
            bottom: 2rem;
            right: 2rem;
            overflow: hidden;
            width: 5rem;
            aspect-ratio: 1 / 1;

            img {
              position: relative;
              top: 0;
              width: 200%;
              height: 100%;
            }
          }
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &.message::before {
          background: url(../images/common/message.jpg) center / cover;
        }
        &.philosophy::before {
          background: url(../images/common/philosophy.jpg) center / cover;
        }
        &.history::before {
          background: url(../images/common/history.jpg) center / cover;
        }
        &.aboutus::before {
          background: url(../images/common/about.jpg) center / cover;
        }

        &:hover {
          opacity: 0.7;

          img {
            left: -100%;
          }
        }
      }
    }
  }
}

@include f.mq(sp) {
  main {
    .mv {
      max-width: 70rem;
      padding-block: 22rem 11rem;

      hgroup {
        text-align: center;

        h1 {
          font-size: 7.5rem;
          font-weight: 500;
        }

        p {
          font-size: 1.5rem;

          font-size: 2.4rem;
        }
      }
    }

    .lower-content {
      max-width: 68rem;
    }

    .lower-page-link {
      ul {
        flex-direction: column;
        align-items: center;
        max-width: 70rem;

        li {
          width: 54rem;
          aspect-ratio: 34/8;

          &.message::before {
            background: url(../images/common/message.jpg) 0 32% / cover;
          }
        }
      }
    }
  }
}
