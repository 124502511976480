@use "../../base/shortcut-functions" as f;
@use "../modules" as modules;

.sdgs {
  padding: 0;

  .attempt {
    margin: 8rem auto 9rem;
    max-width: 108rem;

    > hgroup {
      p {
        text-align: center;
        font-size: 1.8rem;
        line-height: 2;
      }

      h2 {
        margin-top: 7rem;
        text-align: center;
        font-size: 4rem;
        font-weight: 300;
        letter-spacing: 0.1em;
      }

      figure {
        margin: 8.4rem auto 0;
        width: 49rem;
        aspect-ratio: 49 / 6;
      }
    }

    .intro {
      margin-top: 9.8rem;

      > li {
        & + li {
          margin-top: 7rem;
        }

        h3 {
          font-size: 2.6rem;
          font-weight: 300;
        }

        > div {
          display: flex;
          justify-content: space-between;
          margin-top: 2.4rem;
          padding-top: 2.4rem;
          border-top: 1px solid #4c565d;

          .left {
            width: 63rem;

            li {
              display: flex;
              gap: 0.4rem;
              font-size: 1.4rem;
              line-height: 1.6;

              &::before {
                content: "-";
                color: #4c565d;
              }

              & + li {
                margin-top: 1rem;
              }
            }
          }

          .right {
            display: flex;
            flex-wrap: wrap;
            gap: 1.2rem;
            padding-top: 0.4rem;
            width: 41.4rem;

            li {
              width: 13rem;
              aspect-ratio: 1 / 1;
            }
          }
        }
      }
    }

    .external-links {
      margin: 10rem auto 0;
      display: flex;
      justify-content: space-between;
      width: 84rem;

      li {
        position: relative;
        width: 40rem;
        aspect-ratio: 40 / 17;
        border-radius: 1rem;
        background-color: #fff;

        a {
          display: inline-block;
          width: 100%;
          height: 100%;

          figure {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        &:first-of-type {
          a {
            figure {
              width: 29.4rem;
              aspect-ratio: 147 / 20;
            }
          }
        }

        &:last-of-type {
          a {
            figure {
              width: 23.8rem;
              aspect-ratio: 119 / 46;
            }
          }
        }
      }
    }
  }
}

@include f.mq(sp) {
  .sdgs {
    .attempt {
      max-width: 68rem;

      > hgroup {
        p {
          font-size: 3.2rem;
        }

        h2 {
          font-size: 5rem;
        }

        figure {
          width: 54rem;
        }
      }

      .intro {
        > li {
          h3 {
            font-size: 3.8rem;
          }

          > div {
            flex-direction: column;
            gap: 4rem;

            .left {
              width: 100%;

              li {
                font-size: 2.8rem;
                line-height: 2;
              }
            }

            .right {
              gap: 3rem 5rem;
              width: 100%;

              li {
                width: 18rem;
              }
            }
          }
        }
      }

      .external-links {
        flex-direction: column;
        align-items: center;
        gap: 4rem;
        width: 100%;

        li {
          width: 48rem;

          &:first-of-type {
            a {
              figure {
                width: 34rem;
              }
            }
          }

          &:last-of-type {
            a {
              figure {
                width: 30rem;
              }
            }
          }
        }
      }
    }
  }
}
