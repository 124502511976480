@use "../../base/shortcut-functions" as f;
@use "../modules" as modules;

.breadcrumb-wrapper {
  margin-inline: auto;
  max-width: 108rem;
  font-size: 1.4rem;

  .breadcrumb {
    padding: 0;

    a {
      color: #237aa8;
      text-decoration: underline;
    }

    li {
      display: flex;
      align-items: baseline;
      gap: 0.6rem;
      margin: 0 0.6rem 0 0;
      padding: 0;

      &:not(:last-of-type) {
        &::after {
          content: "";
          display: block;
          width: 0.8rem;
          aspect-ratio: 40 / 37;
          background: url(../images/common/svg/breadcrumb-arrow.svg) center / cover;
        }
      }

      &::before {
        content: none;
      }
    }
  }
}

@include f.mq(sp) {
  .breadcrumb-wrapper {
    max-width: 70rem;
    font-size: 2.4rem;
  }
}
