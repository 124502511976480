@mixin hover {
  &:focus {
    @content;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}
