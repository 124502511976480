@use "../../base/shortcut-functions" as f;
@use "../modules" as modules;

.message {
  main {
    .representative {
      padding-block: 8rem 9rem;

      .person-wrapper {
        margin-inline: auto;
        max-width: 136.6rem;

        .person {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-inline: auto 14.4rem;
          max-width: 86rem;

          hgroup {
            p {
              font-size: 1.4rem;
              font-weight: 300;
            }

            h3 {
              margin-top: 1.6rem;
              font-size: 2.6rem;
            }
          }

          figure {
            width: 60.6rem;
            aspect-ratio: 101 / 75;
          }
        }
      }

      .texts {
        margin: 10rem auto 0;
        max-width: 64rem;
        font-size: 1.6rem;
        line-height: 2.4;

        p {
          text-indent: 0.8em;

          & + p {
            margin-top: 4rem;
          }
        }
      }

      .strategy {
        position: relative;
        margin-top: 10.8rem;

        &::before {
          content: "";
          position: absolute;
          width: 90vw;
          height: 100%;
          z-index: -1;
          background-color: #e8e8e8;
        }

        > .strategy-inner {
          margin-inline: auto;
          max-width: 48rem;
          padding-block: 6rem 6.6rem;

          h2 {
            font-size: 3.6rem;
            font-weight: 300;
          }

          figure {
            position: relative;
            margin-top: 5.6rem;
            text-align: center;
            font-weight: 300;

            img {
              width: 37.8rem;
              aspect-ratio: 189 / 163;
            }

            p {
              display: flex;
              gap: 0.6rem;
              position: absolute;
              font-size: 1.6rem;
              text-align: start;

              &:nth-of-type(1) {
                top: 17%;
                left: -12%;
                color: #237aa8;
                font-weight: 500;
                text-decoration: underline;

                &::after {
                  content: "";
                  margin-top: 1.4rem;
                  width: 10rem;
                  height: 1px;
                  background-color: #bf0000;
                }
              }

              &:nth-of-type(2) {
                top: 35%;
                right: -50%;

                &::before {
                  content: "";
                  margin-top: 1.6rem;
                  width: 13rem;
                  height: 1px;
                  background-color: #bf0000;
                }
              }

              &:nth-of-type(3) {
                top: 54%;
                right: -50%;

                &::before {
                  content: "";
                  margin-top: 1.6rem;
                  width: 10rem;
                  height: 1px;
                  background-color: #bf0000;
                }
              }
            }

            figcaption {
              margin-top: 3.4rem;
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
}

@include f.mq(sp) {
  .message {
    main {
      .representative {
        .person-wrapper {
          max-width: 70rem;

          .person {
            align-items: center;
            flex-direction: column-reverse;
            margin-inline: auto;
            max-width: 70rem;

            hgroup {
              margin-top: 2rem;
              text-align: center;

              p {
                font-size: 2.2rem;
              }

              h3 {
                font-size: 3.8rem;
              }
            }
          }
        }

        .texts {
          max-width: 60rem;
          font-size: 2.8rem;
        }

        .strategy {
          &::before {
            width: 100vw;
          }

          > .strategy-inner {
            max-width: 70rem;

            h2 {
              font-size: 3.6rem;
              font-weight: 300;

              text-align: center;
              font-size: 4.4rem;
            }

            figure {
              position: relative;
              margin-top: 5.6rem;
              text-align: center;
              font-weight: 300;

              img {
                width: 40rem;
              }

              p {
                font-size: 1.8rem;

                &:nth-of-type(1) {
                  left: 4%;
                }

                &:nth-of-type(2) {
                  top: 9%;
                  right: 0;

                  &::before {
                    position: absolute;
                    top: 110%;
                    left: -40%;
                    margin-top: 0;
                    width: 8rem;
                    transform: rotate(-45deg);
                  }
                }

                &:nth-of-type(3) {
                  top: 32%;
                  right: 4%;

                  &::before {
                    position: absolute;
                    top: 72%;
                    left: -38%;
                    margin-top: 0;
                    width: 6rem;
                    transform: rotate(-45deg);
                  }
                }
              }

              figcaption {
                font-size: 3rem;
              }
            }
          }
        }
      }
    }
  }
}
