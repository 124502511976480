@use "../../base/shortcut-functions" as f;
@use "../modules" as modules;

.access {
  main {
    .access-wrapper {
      .map {
        margin: 5rem auto;
        max-width: 78rem;

        h2 {
          font-size: 2.6rem;
          font-weight: 300;
        }

        address {
          margin-top: 2.4rem;
          padding-top: 2.4rem;
          border-top: 1px solid #c4c4c4;

          p {
            font-size: 1.5rem;

            & + p {
              margin-top: 1rem;
            }
          }
        }

        iframe {
          margin-top: 3.6rem;
          width: 100%;
          aspect-ratio: 39 / 19;
        }
      }

      .from-station {
        background-color: #e8e8e8;

        .from-station-inner {
          margin: 5rem auto 0;
          padding-block: 5.4rem;
          max-width: 78rem;

          h3 {
            font-size: 2rem;
          }

          dl {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: 1px solid #c4c4c4;

            > div {
              dt {
                font-size: 1.6rem;
                font-weight: normal;
              }

              dd {
                margin-top: 1.4rem;
                font-size: 1.4rem;
                line-height: 1.5;
              }

              & + div {
                margin-top: 2.4rem;
              }
            }
          }
        }
      }

      .from-car {
        .from-car-inner {
          margin: 0 auto 5rem;
          padding-block: 5.4rem;
          max-width: 78rem;

          h3 {
            font-size: 2rem;
          }

          dl {
            margin-top: 2rem;
            padding-top: 2rem;
            border-top: 1px solid #c4c4c4;

            > div {
              dt {
                font-size: 1.6rem;
                font-weight: normal;
              }

              dd {
                margin-top: 1.4rem;
                font-size: 1.4rem;
                line-height: 1.5;
              }

              & + div {
                margin-top: 2.4rem;
              }
            }
          }
        }
      }
    }
  }
}

@include f.mq(sp) {
  .access {
    main {
      .access-wrapper {
        .map {
          max-width: 68rem;

          h2 {
            font-size: 3.8rem;
          }

          address {
            p {
              font-size: 2.8rem;

              & + p {
                margin-top: 1.6rem;
              }
            }
          }

          iframe {
            height: auto;
            aspect-ratio: 1 / 1;
          }
        }

        .from-station {
          .from-station-inner {
            max-width: 68rem;

            h3 {
              font-size: 3.8rem;
            }

            dl {
              > div {
                dt {
                  font-size: 3.2rem;
                }

                dd {
                  font-size: 2.8rem;
                }
              }
            }
          }
        }

        .from-car {
          .from-car-inner {
            max-width: 68rem;

            h3 {
              font-size: 3.8rem;
            }

            dl {
              > div {
                dt {
                  font-size: 3.2rem;
                }

                dd {
                  font-size: 2.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
