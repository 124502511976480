@use "../../base/shortcut-functions" as f;
@use "../modules" as modules;

.topics-detail {
  .topics-content {
    margin: 8.6rem auto 5rem;
    max-width: 108.2rem;

    hgroup {
      > p {
        font-size: 1.6rem;
        font-weight: 500;
      }

      h2 {
        margin-top: 1rem;
        font-size: 2.6rem;
        font-weight: 300;
        line-height: 1.5;
      }
    }

    .topics-content-inner {
      margin-top: 6rem;

      h3 {
        margin-bottom: 3rem;
        padding-bottom: 3rem;
        border-bottom: 1px solid #4c565d;
        font-size: 2rem;
        font-weight: 300;
      }

      h4 {
        margin-bottom: 1rem;
        font-size: 1.8rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2;
      }

      a {
        color: #237aa8;
        text-decoration: underline;
      }

      img {
        display: block;
        width: auto;
        height: auto;

        &.aligncenter {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }

        &.alignleft {
          float: left;
          margin: 0.5em 1em 0.5em 0;
        }

        &.alignright {
          float: right;
          margin: 0.5em 0 0.5em 1em;
        }
      }

      ul {
        width: 64rem;

        li {
          display: flex;
          gap: 0.4rem;
          font-size: 1.4rem;
          line-height: 1.6;

          & + li {
            margin-top: 1rem;
          }

          &::before {
            content: "-";
            color: #4c565d;
          }
        }
      }

      ol {
        padding-left: 1em;
        width: 64rem;
        list-style-type: decimal;

        li {
          font-size: 1.4rem;
          line-height: 1.6;

          & + li {
            margin-top: 1rem;
          }
        }
      }

      .link-btn {
        a {
          color: inherit;
          text-decoration: none;
          font-size: 1.4rem;

          &::after {
            content: "";
            position: relative;
            top: 1.6rem;
            display: inline-block;
            margin-left: 1rem;
            width: 4.9rem;
            aspect-ratio: 1 / 1;
          }
        }

        &.site {
          a {
            &::after {
              background: url(../images/common/svg/link_arr.svg) no-repeat center / cover;
            }

            &:hover {
              &::after {
                background: url(../images/common/svg/link_arr_hov.svg) no-repeat center / cover;
              }
            }
          }
        }

        &.external {
          a {
            &::after {
              background: url(../images/common/svg/link_external_arr.svg) no-repeat center / cover;
            }

            &:hover {
              &::after {
                background: url(../images/common/svg/link_external_arr_hov.svg) no-repeat center / cover;
              }
            }
          }
        }

        &.pdf {
          a {
            &::after {
              background: url(../images/common/svg/link_pdf.svg) no-repeat center / cover;
            }

            &:hover {
              &::after {
                background: url(../images/common/svg/link_pdf_hov.svg) no-repeat center / cover;
              }
            }
          }
        }
      }

      .table-container {
        .table-inner {
          table {
            border: 1px solid #4c565d;

            tbody {
              tr {
                &:nth-of-type(odd) {
                  background-color: #f0f0f0;
                }

                &:nth-of-type(even) {
                  background-color: #e8e8e8;
                }

                th,
                td {
                  padding: 2rem;
                  vertical-align: middle;

                  &:not(:first-of-type) {
                    border-left: 1px solid #c4c4c4;
                  }
                }

                th {
                  font-size: 1.5rem;
                  font-weight: 300;
                }

                td {
                  font-size: 1.4rem;
                }
              }
            }
          }
        }
      }
    }

    .back-topics {
      margin: 10rem 0 5rem;

      > a {
        display: flex;
        align-items: center;
        gap: 2rem;
        margin-inline: auto;
        width: fit-content;

        figure {
          overflow: hidden;
          width: 6.5rem;
          aspect-ratio: 1 / 1;
          transform: rotate(180deg);

          img {
            position: relative;
            top: 0;
            width: 200%;
          }
        }

        &:hover {
          figure {
            img {
              left: -100%;
            }
          }
        }
      }
    }
  }
}

@include f.mq(sp) {
  .topics-detail {
    .topics-content {
      max-width: 68rem;

      hgroup {
        > p {
          font-size: 2.4rem;
        }

        h2 {
          font-size: 3.8rem;
        }
      }

      .topics-content-inner {
        h3 {
          font-size: 3rem;
        }

        h4 {
          font-size: 2.4rem;
        }

        > p {
          font-size: 2.4rem;
        }

        ul {
          width: auto;

          li {
            font-size: 2.2rem;
          }
        }

        ol {
          width: auto;

          li {
            font-size: 2.2rem;
          }
        }

        .table-container {
          overflow: scroll;

          .table-inner {
            width: 108.2rem;

            table {
              tbody {
                tr {
                  th,
                  td {
                    padding: 2rem;

                    &:not(:first-of-type) {
                      border-left: 1px solid #c4c4c4;
                    }
                  }

                  th {
                    font-size: 2.2rem;
                  }

                  td {
                    font-size: 2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
