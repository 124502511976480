@use "../../base/shortcut-functions" as f;
@use "../modules" as modules;

.topics {
  .topics-inner {
    margin: 8rem auto;
    max-width: 78rem;

    dl {
      > div {
        display: flex;
        align-items: baseline;
        gap: 13.8rem;
        padding-block: 2.2rem;
        border-top: 1px solid #c4c4c4;

        dt {
          font-size: 1.6rem;
        }

        dd {
          margin-top: -0.1rem;
          font-size: 1.4rem;
          line-height: 1.8;

          a {
            span.file {
              display: inline-block;
              width: 1.6rem;
              aspect-ratio: 1 / 1;
              vertical-align: sub;
              background: url(../images/common/svg/pdf-icon.svg) center / cover;
            }
          }
        }

        &:last-of-type {
          border-bottom: 1px solid #c4c4c4;
        }
      }
    }

    .pagenation {
      display: flex;
      gap: 0.6rem;
      margin: 8.5rem auto 0;
      width: fit-content;

      li {
        display: grid;
        place-items: center;
        width: 3rem;
        font-size: 1.6rem;

        a {
          display: grid;
          place-items: center;
          width: 100%;
          aspect-ratio: 1 / 1;

          &.pagenation-current {
            background-color: #bf0000;
            color: #fff;
          }

          &.pagination-first {
            width: 1.3rem;
            aspect-ratio: 1 / 1;
            transform: rotate(180deg);
          }

          &.pagination-last {
            width: 1.3rem;
            aspect-ratio: 1 / 1;
          }

          &.pagination-prev {
            width: 0.8rem;
            transform: rotate(180deg);
          }

          &.pagination-next {
            width: 0.8rem;
          }
        }

        &.pagination-prev-gray {
          img {
            width: 0.8rem;
          }
        }

        &.pagination-next-gray {
          img {
            width: 0.8rem;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

@include f.mq(sp) {
  .topics {
    .topics-inner {
      max-width: 68rem;

      dl {
        > div {
          gap: 3rem;

          dt {
            font-size: 1.6rem;

            font-size: 2.4rem;
          }

          dd {
            font-size: 2.2rem;
            line-height: 1.6;

            a {
              span.file {
                display: inline-block;
                width: 2.2rem;
                aspect-ratio: 1/1;
                vertical-align: middle;
                background: url(../images/common/svg/pdf-icon.svg) center / cover;
              }
            }
          }
        }
      }

      .pagenation {
        gap: 1rem;

        li {
          width: 6rem;
          font-size: 2.8rem;

          a {
            &.pagination-first {
              width: 3rem;
            }

            &.pagination-last {
              width: 3rem;
            }

            &.pagination-prev {
              width: 1.8rem;
            }

            &.pagination-next {
              width: 1.8rem;
            }
          }

          &.pagination-prev-gray {
            img {
              width: 1.8rem;
            }
          }

          &.pagination-next-gray {
            img {
              width: 1.8rem;
            }
          }
        }
      }
    }
  }
}
